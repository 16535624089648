
import { defineComponent } from 'vue';
import Container from '@/components/Container.vue';

export default defineComponent({
  name: 'Reports',
  components: {
    Container,
  },
});
